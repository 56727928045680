<template>
  <div class="insert-payment-gateway">

    <div class="status-fields">

      <div class="insert-payment-gateway-field-box pb-2">
        <span>{{ $t('treasury.paymentGateways.labels.status') }}</span>
        <custom-select :default="paymentGateway.status.value"
                       :options="status"
                       v-model="paymentGateway.status.value"/>
      </div>

      <div class="insert-payment-gateway-field-box pb-2">
        <span>{{ $t('treasury.paymentGateways.labels.type') }}</span>
        <custom-select :default="paymentGateway.type.value"
                       :options="types"
                       v-model="paymentGateway.type.value"/>
      </div>

      <div v-if="paymentGateway.type.value.value === 1" class="insert-payment-gateway-field-box">
        <span>{{ $t('treasury.paymentGateways.labels.provider') }}</span>
        <custom-select :default="paymentGateway.gatewayProvider.value"
                       :options="paymentGatewayProviders"
                       v-model="paymentGateway.gatewayProvider.value"/>
      </div>

      <div v-if="checkUserPermissions('bank.show')" class="insert-payment-gateway-field-box">
        <span>{{ $t('treasury.paymentGateways.labels.bank') }}</span>
        <custom-select :default="paymentGateway.bank.value"
                       :options="banks"
                       v-model="paymentGateway.bank.value"/>
      </div>

    </div>

    <div class="general-fields">

      <div class="insert-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.title')}`"
                               :rtl="true"
                               align="right"
                               :regex="titleRegex"
                               :classes="{'w-full': true}"
                               v-model="paymentGateway.title"/>
      </div>

      <div class="insert-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.endTime')}`"
                               :rtl="false"
                               :classes="{'w-full': true, 'rtl': true}"
                               :regex="endTimeRegex"
                               v-model="paymentGateway.endTime"/>
      </div>

      <div class="insert-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.merchantCode')}`"
                             :rtl="false"
                             :classes="{'w-full': true, 'rtl': true}"
                             :is-empty="false"
                             v-model="paymentGateway.merchantCode"/>
      </div>

      <div v-if="paymentGateway.type.value.value !== 1 || paymentGateway.gatewayProvider.value.hasTerminalCode" class="insert-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.terminalCode')}`"
                             :rtl="false"
                             :classes="{'w-full': true, 'rtl': true}"
                             :is-empty="false"
                             v-model="paymentGateway.terminalCode"/>
      </div>

      <div class="insert-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.description')}`"
                               :rtl="true"
                               align="right"
                               :classes="{'w-full': true}"
                               :is-empty="true"
                               v-model="paymentGateway.description"/>

      </div>
    </div>

    <button v-show="false"
            id="insertPaymentGatewaysBTN"
            @click="insertPaymentGateway"/>
  </div>
</template>

<script>
import axios from 'axios'
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import CustomSelect from '../../../../../components/customSelect/customSelect'
import {getBanks} from '../../../../../http/requests/banks'
import {getPaymentGatewayProviders, insertPaymentGateway} from '../../../../../http/requests/paymentGateways'
import CustomNumberInput from '../../../../../components/customInput/customNumberInput'
import {checkUserPermissions} from "../../../../../assets/js/functions";

export default {
  name: 'insertPaymentGateway',
  components: {CustomNumberInput, CustomSelect, CustomValidateInput},
  data () {
    return {
      titleRegex: this.$validator('regex.treasury.paymentGateway.name'),
      endTimeRegex: this.$validator('regex.time'),
      paymentGateway: {
        status: {
          value: {
            label: this.$t('treasury.paymentGateways.status.enable'),
            value: 1
          },
          isValid: false
        },
        title: {
          value: '',
          isValid: false
        },
        bank: {
          value: {
            label: '-',
            value: 0
          },
          isValid: false
        },
        endTime: {
          value: '21:00',
          isValid: true
        },
        merchantCode: {
          value: '',
          isValid: false
        },
        terminalCode: {
          value: '',
          isValid: false
        },
        description: {
          value: '',
          isValid: false
        },
        type: {
          value: {
            label: '-',
            value: '-'
          },
          isValid: false
        },
        gatewayProvider: {
          value: {
            label: '-',
            value: null,
            hasTerminalCode: true
          },
          isValid: false
        }
      },
      paymentGatewayProviders: [],
      status: [
        {
          label: this.$t('treasury.paymentGateways.status.enable'),
          value: 1
        },
        {
          label: this.$t('treasury.paymentGateways.status.disable'),
          value: 0
        }
      ],
      types: [
        {
          label: this.$t('treasury.paymentGateways.types.online'),
          value: 1
        },
        {
          label: this.$t('treasury.paymentGateways.types.pos'),
          value: 2
        }
      ],
      banks: [],
      actions: [
        {
          toolbar: [
            {
              id: 'insertPaymentGatewaysBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'paymentGateways'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    /*setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto xl:w-1/3 md:w-1/2')
    }, 50)*/

    if (checkUserPermissions('bank.show')) {
      this.getBanks()
    }

    this.getPaymentGatewayProviders()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getPaymentGatewayProviders () {
      getPaymentGatewayProviders().then((response) => {
        const gateways = response.data.data
        gateways.forEach((item) => {
          this.paymentGatewayProviders.push({
            label: item.name ? item.name : '',
            value: item.id,
            hasTerminalCode: item.has_terminal_code === 1
          })
        })
      })
    },
    getBanks () {
      getBanks().then(response => {
        const banks = response.data.data
        banks.forEach((bank) => {
          this.banks.push({
            value: bank.id,
            label: bank.name
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    createSuggestTitle () {
      if (this.paymentGateway.type.value.value !== '-' && this.paymentGateway.bank.value.value !== 0) {
        this.paymentGateway.title.value = this.$t('treasury.paymentGateways.insert.suggestTitle', {
          type: this.paymentGateway.type.value.label,
          bank: this.paymentGateway.bank.value.label
        })
        this.paymentGateway.title.isValid = true
      }
    },
    insertPaymentGateway () {
      if (this.paymentGateway.bank.value.value === 0 ||
        !this.paymentGateway.title.isValid ||
        (this.paymentGateway.gatewayProvider.value.value === 0 && this.paymentGateway.type.value.value === 1) ||
        !this.paymentGateway.merchantCode.isValid ||
        (!this.paymentGateway.terminalCode.isValid && this.paymentGateway.gatewayProvider.value.hasTerminalCode) ||
        !this.paymentGateway.endTime.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.paymentGateways.notifications.incorrectData'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      const paymentGateway = {
        title: this.paymentGateway.title.value,
        merchant_code: this.paymentGateway.merchantCode.value,
        terminal_code: this.paymentGateway.terminalCode.value,
        bank_id: this.paymentGateway.bank.value.value,
        type: this.paymentGateway.type.value.value,
        enabled: this.paymentGateway.status.value.value,
        provider_id: this.paymentGateway.gatewayProvider.value.value,
        description: this.paymentGateway.description.value,
        end_time: `${this.paymentGateway.endTime.value}`
      }
      insertPaymentGateway(paymentGateway).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.paymentGateways.notifications.insert.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        // this.$router.push({name: 'paymentGateways'})
        this.$store.dispatch('helper/changePaymentGateway')
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.paymentGateways.notifications.insert.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    }
  },
  watch: {
    'paymentGateway.type': {
      handler () {
        this.createSuggestTitle()
      },
      deep: true
    },
    'paymentGateway.bank': {
      handler () {
        this.createSuggestTitle()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.insert-payment-gateway {
  padding: 0 !important;

  .status-fields, .general-fields {
    padding: 1.25rem;
  }

  .status-fields {
    border-radius: .5rem;
    background: #cecece22;
    border: 1px solid #cecece;
  }

  .insert-payment-gateway-field-box {
    width: 100%;
    margin-top: 10px;
    position: relative;

    span {
      position: absolute;
      z-index: 300;
      font-size: 12px;
      top: -10px;
      left: 7px;
      backdrop-filter: sepia(1);
      padding: 0 5px;
      background: linear-gradient(180deg, #f8f8f8, #ffffff);

      &.invalid-text-helper {
        top: unset !important;
        background-color: inherit;
      }
    }

    .useral-custom-element-select {

      .selected {
        font-size: 13px;
        line-height: 35px;
        height: 35px;
      }

      .items {
        font-size: 13px;

        div {
          line-height: 35px;
          height: 35px;
        }
      }
    }
  }

  .general-fields {
    .insert-payment-gateway-field-box {
      margin-bottom: 35px;
    }
  }
}

.vx-card .vx-card__collapsible-content .vx-card__body {
  /*padding: 0 !important;*/
}
</style>
